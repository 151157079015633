import { useContext } from "react";

// contexts
import { currencyContext } from "../../../../../context/currency-provider/CurrencyProvider";
import { localesContext } from "../../../../../context/local-provider/LocalProvider";

// icons
import { DownloadIcon } from "../../../../../assets/icons/DownloadIcon";

// helpers
import { formattedDate } from "../../../../../helpers/format-date";
import { getMembershipTitle } from "../../../../../helpers/get-membership-title";

// types
import type { MembershipType } from "../../../../../context/membership-provider/MembershipProvider.types";

// styles
import styles from "./MembershipItem.module.scss";

//translation
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

type MembershipItemProps = {
  membership: MembershipType;
};

export function MembershipItem({ membership }: MembershipItemProps) {
  const { i18n, locale } = useContext(localesContext);

  const { getCurrencySymbol } = useContext(currencyContext);

  const membershipPriceTime = (
    type: "ONE_MONTH" | "ONE_YEAR" | "THREE_YEARS"
  ) => {
    switch (type) {
      case "ONE_MONTH":
        return t(i18n)`Month`;

      case "ONE_YEAR":
        return t(i18n)`Year`;

      case "THREE_YEARS":
        return t(i18n)`Total`;

      default:
        return "";
    }
  };

  const pdfUrlUA = (type: "ONE_MONTH" | "ONE_YEAR" | "THREE_YEARS") => {
    switch (type) {
      case "ONE_MONTH":
        return "https://pokerpowerhouse.s3.eu-central-1.amazonaws.com/pictures/plans/1month.pdf";

      case "ONE_YEAR":
        return "https://pokerpowerhouse.s3.eu-central-1.amazonaws.com/pictures/plans/1year.pdf";

      case "THREE_YEARS":
        return "https://pokerpowerhouse.s3.eu-central-1.amazonaws.com/pictures/plans/3years.pdf";

      default:
        return "";
    }
  };

  const pdfUrlEN = (type: "ONE_MONTH" | "ONE_YEAR" | "THREE_YEARS") => {
    switch (type) {
      case "ONE_MONTH":
        return "https://pokerpowerhouse.s3.amazonaws.com/pictures/docs/1month.pdf";

      case "ONE_YEAR":
        return "https://pokerpowerhouse.s3.amazonaws.com/pictures/docs/1year.pdf";

      case "THREE_YEARS":
        return "https://pokerpowerhouse.s3.amazonaws.com/pictures/docs/3years.pdf";

      default:
        return "";
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <p>
          {getMembershipTitle(membership.type.name)}
          <span>
            ({t(i18n)`until`} {formattedDate(membership.endDate, false)})
          </span>
        </p>
      </div>
      <div className={styles.price}>
        <p>
          {getCurrencySymbol()}{" "}
          {membership.type.discountPrice
            ? membership.type.discountPrice
            : membership.type.price}
          <span>/{membershipPriceTime(membership.type.name)}</span>
        </p>
      </div>
      <a
        className={styles.linkWrapper}
        href={
          locale === "ua"
            ? pdfUrlUA(membership.type.name)
            : pdfUrlEN(membership.type.name)
        }
        download="membership.pdf"
        target="_blank"
      >
        {" "}
        <Trans>Download as PDF</Trans> <DownloadIcon width={14} height={14} />
      </a>
    </div>
  );
}
