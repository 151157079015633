// components
import { ProfileForm } from "./profile-form/ProfileForm";
import { OrderHistory } from "./order-history/OrderHistory";
import { Membership } from "./membership/Membership";

// styles
import styles from "./ProfileUserScreen.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function ProfileAdminScreen() {
  return (
    <section className={styles.section}>
      <div className={styles.contentWrapper}>
        <h5>
          <Trans>Profile</Trans>
        </h5>

        <div className={styles.profileWrapper}>
          <ProfileForm />

          <div className={styles.profileAside}>
            <OrderHistory />
            <Membership />
          </div>
        </div>
      </div>
    </section>
  );
}
